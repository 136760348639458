import React from 'react'
import styled from 'styled-components'
import ProjectCard from './ProjectCard'

const Container = styled.div`
  // margin-top: 200px;
  margin-bottom: 80px;
`

const ProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LatestProjects = ({ projects }) => {
  console.log('LatestProjects, slug:', projects[0].slug)
  return (
    <Container>
      <ProjectList>
        {projects.map((project, i) => (
          <ProjectCard
            key={project.slug.current}
            flex={i === 0 && '1 1 100%'}
            project={project}
            title={project.title}
            caption={project.caption}
            description={project.description}
            img={project.coverImage.asset.url}
            slug={project.slug.current}
          />
        ))}
      </ProjectList>
    </Container>
  )
}

export default LatestProjects
