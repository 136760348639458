import React, { useRef } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import Seo from 'components/seo'
import Landing from 'components/Landing'
import LatestProjects from 'components/LatestProjects'
import BackLink from 'components/BackLink'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

// import smoothscroll from 'smoothscroll-polyfill'

// smoothscroll.polyfill()

const SectionLink = styled.h3`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

export const query = graphql`
  query {
    allSanityProject(sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          title
          caption
          description
          id
          coverImage {
            alt
            asset {
              url
              gatsbyImageData(fit: FILLMAX)
            }
          }
          sourceLink
          demoLink
          stack {
            link
            title
          }
          slug {
            current
          }
          _updatedAt
          _createdAt
        }
      }
      totalCount
    }
    sanityPage(slug: { current: { eq: "projects" } }) {
      intro
      title
      hero {
        backgroundImage {
          asset {
            url
            gatsbyImageData(fit: FILLMAX)
          }
        }
        caption
        title
      }
    }
  }
`

const ProjectsPage = ({ data }) => {
  console.log('ProjectsPage data:', data)

  // const projects = []
  const projects = data.allSanityProject.edges.map(edge => edge.node)
  const page = data.sanityPage

  const sectionNavEl = useRef(null)

  /**
   * https://stackoverflow.com/a/52998588
   */
  const scrollToRef = ref =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
    })

  return (
    <>
      <Landing
        background={page.hero.backgroundImage?.asset.url}
        backgroundImage={page.hero.backgroundImage?.asset.gatsbyImageData}
        title={
          <h1
            style={{
              paddingLeft: 20,
              color: '#fff',
            }}
          >
            {page.hero.title}
          </h1>
        }
        height={80}
        grayscale={10}
        brightness={0.4}
        blur={0}
      >
        <div
          style={{
            color: '#fff',
            position: 'relative',
            // margin: 'auto',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: 30,
          }}
        >
          <p
            style={{
              maxWidth: 880,
              margin: 'auto',
              fontStyle: 'italic',
            }}
          >
            {page.hero.caption}
          </p>
        </div>
      </Landing>
      <Layout>
        <Seo title="Projects" />
        {/* <h1>Projects:</h1> */}
        {/* <p>A presentation of various projects...</p> */}
        <div
          ref={sectionNavEl}
          style={{
            position: 'sticky',
            top: 0,
            height: '20vh',
            display: 'flex',
            justifyContent: 'space-around',
            paddingTop: '8vh',
          }}
        >
          <div>
            <SectionLink onClick={() => scrollToRef(sectionNavEl)}>
              Web
            </SectionLink>
          </div>
          <div>
            <h3> | </h3>
          </div>
          <div>
            <Tooltip title={<Typography arrow>Coming soon</Typography>}>
              <h3>Visual</h3>
            </Tooltip>
          </div>
          <div>
            <h3> | </h3>
          </div>
          <div>
            <Tooltip title={<Typography arrow>Coming soon</Typography>}>
              <h3>Sound</h3>
            </Tooltip>
          </div>
        </div>
        <LatestProjects projects={projects} />
        <BackLink />
      </Layout>
    </>
  )
}

export default ProjectsPage
