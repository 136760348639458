import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useTheme } from '@material-ui/core/styles'
import useHover from '../hooks/useHover'

const ProjectContainer = styled.div`
	flex: ${props => props.flex || '1 1 350px'};
	height: 340px;
	position: relative;
	background: url("${props => props.img}") no-repeat;
	background-size: cover;
	background-color: ${({ theme }) => theme.palette.background.default};
  margin: 10px;
	
	filter: grayscale(100%);
	:hover {
		filter: grayscale(0%) ;
		transition: all .5s ease;
	}

	@media (max-width: 600px) {
		width: 100%;
		padding: 0;
	}
`

const ProjectContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(58, 59, 58, 0.8);
  transition: all 0.3s ease;

  :hover {
    background: none;
    transition: all 0.3s ease;
  }
`

const ProjectTitle = styled.h3`
  background-color: ${({ theme, isHovered }) =>
    isHovered ? 'rgba(0,0,0,0)' : theme.palette.background.default};
  color: ${({ theme, isHovered }) =>
    isHovered ? 'rgba(0,0,0,0)' : theme.palette.text.primary};
  max-width: fit-content;
  padding: 0 5px 3px 5px;
  margin-top: 20px;
  padding: 0 1.075rem;
  transition: all 0.3s ease;
`

const ProjectCaptionContainer = styled.div`
  padding: 5px;
  padding-right: 10px;
  bottom: 20px;
`

const ProjectCaption = styled.p`
  background-color: ${({ theme, isHovered }) =>
    isHovered ? 'rgba(0,0,0,0)' : theme.palette.background.default};
  color: ${({ theme, isHovered }) =>
    isHovered ? 'rgba(0,0,0,0)' : theme.palette.text.primary};
  font-size: 0.75em;
  line-height: 0.75;
  display: inline;
  padding: 0.4em 0px;
  padding-left: 1.075rem;
  box-shadow: 5px 0 0
      ${({ theme, isHovered }) =>
        isHovered ? 'rgba(0,0,0,0)' : theme.palette.background.default},
    -5px 0 0 ${({ theme, isHovered }) => (isHovered ? 'rgba(0,0,0,0)' : theme.palette.background.default)};
  transition: all 0.3s ease;
`

export default function ProjectCard({ project, flex }) {
  const theme = useTheme()
  const [hoverRef, isHovered] = useHover()

  return (
    <ProjectContainer
      ref={hoverRef}
      theme={theme}
      flex={flex}
      img={project.coverImage.asset.url}
    >
      <Link to={`/projects/${project.slug.current}`}>
        <ProjectContent>
          <ProjectTitle theme={theme} isHovered={isHovered}>
            {project.title}
          </ProjectTitle>
          <ProjectCaptionContainer theme={theme} isHovered={isHovered}>
            <ProjectCaption theme={theme} isHovered={isHovered}>
              {project.caption}
            </ProjectCaption>
          </ProjectCaptionContainer>
        </ProjectContent>
      </Link>
    </ProjectContainer>
  )
}
